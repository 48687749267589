import actions from './actions';
import mutations from './mutations';
import state from './state';

const paymentsStore = {
  namespaced: true,
  actions,
  mutations,
  state,
};

export default paymentsStore;
