export default {
  setItems(state, bookings) {
    state.items = bookings;
  },

  clearItems(state) {
    state.items = [];
  },

  addItems(state, bookings) {
    state.items = [...state.items, ...bookings];
  },

  toggleLoadingStatus(state) {
    state.isLoading = !state.isLoading;
  },

  setHasMoreContent(state, value) {
    state.hasMoreContent = value;
  },
};
