import ServiceFactory from '@/api';
import TokenStorage from '@/TokenStorage';

const AuthService = ServiceFactory.get('auth');

function requestOTP({ commit, dispatch }, phone) {
  commit('toggleLoadingStatus', 'otpRequest');
  return AuthService.requestOTP(phone)
    .then((response) => {
      console.debug('got api response');
      if (response.success && response.data && response.data.session) {
        commit('setOtpSessionKey', response.data.session);
      } else {
        dispatch(
          'notify',
          {
            type: 'error',
            message: 'there was an error processing your request',
          },
          { root: true },
        );
      }
      return response;
    })
    .finally(() => {
      commit('toggleLoadingStatus', 'otpRequest');
    });
}

function verifyOTP({ commit, state }, payload) {
  const data = {
    session: state.otpSessionKey,
    ...payload,
  };
  commit('toggleLoadingStatus', 'otpValidation');
  return AuthService.verifyOTP(data)
    .then((response) => {
      if (response.success) {
        commit('resetOtpSessionKey');
        commit('setTokenValues', {
          accessToken: response.data.access_token,
          refreshToken: response.data.refresh_token,
          expiresIn: response.data.expires_in,
        });
      }

      return response;
    })
    .finally(() => {
      commit('toggleLoadingStatus', 'otpValidation');
    });
}

function loadTokens({ commit }) {
  return new Promise((resolve) => {
    const payload = TokenStorage.getTokens();
    if (payload && payload.accessToken && payload.refreshToken) {
      commit('setTokenValues', payload);
    }
    resolve(true);
  });
}

function logout({ commit, state }) {
  const { accessToken } = state;
  return AuthService.revokeToken(accessToken)
    .then((response) => {
      if (response.success) {
        commit('clearTokenValues');
      }
      return response;
    });
}

function getNewToken({ commit, state }) {
  const { refreshToken } = state;
  return AuthService.refreshToken(refreshToken)
    .then((response) => {
      if (response.success) {
        commit('setTokenValues', {
          accessToken: response.data.access_token,
          refreshToken: response.data.refresh_token,
          expiresIn: response.data.expires_in,
        });
      }
      return response;
    });
}

export default {
  requestOTP,
  verifyOTP,
  loadTokens,
  logout,
  getNewToken,
};
