export default {
  toggleLoadingStatus(state) {
    console.log(state.paymentIsLoading);
    state.paymentIsLoading = !state.paymentIsLoading;
    console.log(state.paymentIsLoading);
  },

  setStatus(state, chargesEnabled) {
    state.isOnboarded = chargesEnabled;
  },
};
