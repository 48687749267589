export default {
  isLoading: (state) => (field) => {
    let key = field;
    if (!key) {
      key = 'main';
    }
    return state.isLoading[key];
  },

  upcomingFunctions: (state) => state.eventData.count_upcoming_functions || 0,

  upcomingExperiences: (state) => state.eventData.count_upcoming_experiences || 0,

  upcomingEvents: (state) => state.eventData.count_upcoming_events || 0,
};
