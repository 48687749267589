const state = {
  accessToken: '',
  expiresIn: null,
  refreshToken: '',
  otpSessionKey: '',
  isLoading: {
    main: false,
    otpRequest: false,
    otpValidation: false,
  },
};

export default state;
