import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import publicRoutes from './public';
import privateRoutes from './private';

Vue.use(VueRouter);

const routes = publicRoutes.concat(privateRoutes);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to, from, next) => {
  const isAuthenticated = store.getters['auth/isLoggedIn'];
  const { isStaff } = store.state.profile;
  const isPublic = to.meta.public;

  if (!isPublic && !isAuthenticated) {
    return next({
      name: 'Login',
      query: { nextUrl: to.path },
    });
  }
  if (to.path === '/') { // no root access
    return next({ name: 'Dashboard' });
  }
  if (to.meta.staffOnly && !isStaff) {
    store.dispatch(
      'notify',
      {
        type: 'warning',
        message: 'You do not have access to this resource',
      },
    );
    return next({ name: 'Dashboard' });
  }
  return next();
});

export default router;
