import Vue from 'vue';
import Vuex from 'vuex';

import notify from '@/notifications';
import ServiceFactory from '@/api';
import auth from './auth';
import events from './events';
import eventDates from './eventdates';
import tags from './tags';
import venues from './venues';
import images from './images';
import tickets from './tickets';
import profile from './profile';
import dashboard from './dashboard';
import orders from './orders';
import accessRequests from './accessrequests';
import payments from './payments';

const RootService = ServiceFactory.get('root');

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    countries: [],
  },
  mutations: {
    setCountries(state, payload) {
      state.countries = payload;
    },
  },
  actions: {
    notify: (payload) => notify(payload),

    getCountries({ commit }) {
      return RootService.getCountries()
        .then((response) => {
          if (response.success) {
            commit('setCountries', response.data);
          }
          return response;
        });
    },
  },
  modules: {
    auth,
    events,
    tags,
    venues,
    images,
    eventDates,
    tickets,
    profile,
    dashboard,
    orders,
    accessRequests,
    payments,
  },
});
