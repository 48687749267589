export default {
  setItems(state, items) {
    state.items = items;
  },

  clearItems(state) {
    state.items = [];
  },

  toggleLoadingStatus(state) {
    state.isLoading = !state.isLoading;
  },
};
