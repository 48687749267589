import EventsServer from './servers/events';

const RESOURCE = '/event-dates';

function updateEventDate(eventDateId, data) {
  return EventsServer.patch(
    `${RESOURCE}/${eventDateId}/`,
    data,
  );
}

function deleteEventDate(eventDateId) {
  return EventsServer.delete(
    `${RESOURCE}/${eventDateId}/`,
  );
}

export default {
  updateEventDate,
  deleteEventDate,
};
