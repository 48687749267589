import axios from 'axios';

import Interceptors from './interceptors';
import { config } from './common';

const EVENTS_BASE_URL = process.env.VUE_APP_EVENTS_BASE_URL;

const eventsInstance = axios.create({
  baseURL: EVENTS_BASE_URL,
  ...config,
});

eventsInstance.interceptors.response.use(
  (response) => Interceptors.responseParser(response),
);

eventsInstance.interceptors.request.use(
  (requestConfig) => Interceptors.request(requestConfig),
);

export default eventsInstance;
