import EventsServer from './servers/events';

const RESOURCE = '/events';

function get(page, pageSize) {
  return EventsServer.get(
    `${RESOURCE}/`,
    {
      params: {
        page,
        page_size: pageSize,
        event_type: 'function',
        all: 1,
      },
    },
  );
}

function getEvent(eventId) {
  return EventsServer.get(
    `${RESOURCE}/${eventId}/`,
  );
}

function getExperiences(page, pageSize) {
  return EventsServer.get(
    `${RESOURCE}/`,
    {
      params: {
        page,
        page_size: pageSize,
        event_type: 'experience',
        all: 1,
      },
    },
  );
}

function getExperience(experienceId) {
  return getEvent(experienceId);
}

function updateEvent(eventId, data) {
  return EventsServer.patch(
    `${RESOURCE}/${eventId}/`,
    data,
  );
}

function createEvent(data) {
  return EventsServer.post(
    `${RESOURCE}/`,
    data,
  );
}

function deleteEvent(eventId) {
  return EventsServer.delete(
    `${RESOURCE}/${eventId}/`,
  );
}

function getEventNames({
  page, pageSize, searchQuery, tag, venue, eventType,
  hasOrders,
}) {
  const params = {};
  if (page) {
    params.page = page;
  }
  if (pageSize) {
    params.page_size = pageSize;
  }
  if (searchQuery) {
    params.q = searchQuery;
  }
  if (tag) {
    params.tag = tag;
  }
  if (venue) {
    params.venue = venue;
  }
  if (eventType) {
    params.event_type = eventType;
  }
  if (hasOrders != null) {
    params.has_orders = hasOrders ? 'y' : 'n';
  }

  return EventsServer.get(
    `${RESOURCE}/names/`,
    {
      params,
    },
  );
}

export default {
  get,
  getEvent,
  getExperiences,
  getExperience,
  updateEvent,
  createEvent,
  deleteEvent,
  getEventNames,
};
