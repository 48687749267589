import ServiceFactory from '@/api';

const TagsService = ServiceFactory.get('tags');

function getTags({ commit }, payload) {
  commit('toggleLoadingStatus');

  let data = payload;
  if (!data) {
    data = {};
  }
  let { page, pageSize } = data;
  if (!page) {
    page = 1;
  }

  if (!pageSize) {
    pageSize = 10;
  }
  return TagsService.get(page, pageSize)
    .then((response) => {
      if (response.success) {
        if (response.data && response.data.objects) {
          commit('setItems', response.data.objects);
        }
      }

      return response;
    })
    .finally(() => {
      commit('toggleLoadingStatus');
    });
}

function getTag({ commit }, tagId) {
  commit('toggleLoadingStatus');

  return TagsService.getTag(tagId)
    .finally(() => {
      commit('toggleLoadingStatus');
    });
}

export default {
  getTags,
  getTag,
};
