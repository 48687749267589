import ServiceFactory from '@/api';

const TicketService = ServiceFactory.get('tickets');

function getTickets({ commit }, payload) {
  commit('toggleLoadingStatus');

  let data = payload;
  if (!data) {
    data = {};
  }
  let { page, pageSize } = data;
  if (!page) {
    page = 1;
  }

  if (!pageSize) {
    pageSize = 10;
  }
  return TicketService.get(page, pageSize)
    .then((response) => {
      if (response.success) {
        if (response.data && response.data.objects) {
          commit('setItems', response.data.objects);
        }
      }

      return response;
    })
    .finally(() => {
      commit('toggleLoadingStatus');
    });
}

function getTicket({ commit }, venueId) {
  commit('toggleLoadingStatus');

  return TicketService.getTicket(venueId)
    .finally(() => {
      commit('toggleLoadingStatus');
    });
}

function getEventTickets({ commit }, payload) {
  commit('toggleLoadingStatus');

  let data = payload;
  if (!data) {
    data = {};
  }
  let { page, pageSize } = data;
  const { eventId } = data;
  if (!page) {
    page = 1;
  }

  if (!pageSize) {
    pageSize = 10;
  }
  return TicketService.get({ page, pageSize, eventId })
    .then((response) => {
      if (response.success) {
        if (response.data && response.data.objects) {
          const { objects } = response.data;
          if (page === 1) {
            commit('setItems', objects);
          } else {
            commit('addItems', objects);
          }
        }
      }

      return response;
    })
    .finally(() => {
      commit('toggleLoadingStatus');
    });
}

function createTicket({ commit }, data) {
  commit('toggleLoadingStatus');

  return TicketService.createTicket(data)
    .finally(() => {
      commit('toggleLoadingStatus');
    });
}

function updateTicket({ commit }, payload) {
  commit('toggleLoadingStatus');

  const { ticketId, data } = payload;

  return TicketService.updateTicket(ticketId, data)
    .finally(() => {
      commit('toggleLoadingStatus');
    });
}

function deleteTicket({ commit }, ticketId) {
  commit('toggleLoadingStatus');

  return TicketService.deleteTicket(ticketId)
    .finally(() => {
      commit('toggleLoadingStatus');
    });
}

export default {
  getEventTickets,
  getTickets,
  getTicket,
  createTicket,
  updateTicket,
  deleteTicket,
};
