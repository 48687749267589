import authInstance from './servers/auth';

const CLIENT_ID = process.env.VUE_APP_CLIENT_ID;
const MainInstance = authInstance.main;
const TokenInstance = authInstance.token;

function requestOTP(phone) {
  const endpoint = '/otp/request/';
  return MainInstance.post(
    endpoint,
    {
      client_id: CLIENT_ID,
      phone,
    },
  );
}

function verifyOTP(requestData) {
  const endpoint = '/otp/verify/';
  const data = {
    client_id: CLIENT_ID,
    ...requestData,
  };

  return MainInstance.post(
    endpoint,
    data,
  );
}

function revokeToken(token) {
  const endpoint = '/o/revoke_token/';
  const data = {
    token,
    client_id: CLIENT_ID,
    token_type_hint: 'access_token',
  };

  return TokenInstance.post(
    endpoint,
    data,
  );
}

function refreshToken(token) {
  const endpoint = '/auth/token/';
  const data = {
    grant_type: 'refresh_token',
    client_id: CLIENT_ID,
    refresh_token: token,
  };

  return TokenInstance.post(
    endpoint,
    data,
  );
}

export default {
  requestOTP,
  verifyOTP,
  revokeToken,
  refreshToken,
};
