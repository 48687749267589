const routes = [
  {
    path: '/dashboard',
    component: () => import(/* webpackChunkName: "dashboard" */ '@/views/dashboard/Dashboard.vue'),
    meta: {
      crumb: 'dashboard',
    },
    children: [
      {
        path: 'home',
        name: 'Dashboard',
        component: () => import(/* webpackChunkName "dashboard-home" */ '@/views/dashboard/Home.vue'),
        meta: {
          crumb: 'home',
        },
      },
      // {
      //   path: 'events',
      //   component: {
      //     render(c) {
      //       return c('router-view');
      //     },
      //   },
      //   children: [
      //     {
      //       path: '',
      //       name: 'DashboardEvents',
      // eslint-disable-next-line max-len
      //       component: () => import(/* webpackChunkName "dashboard-events" */ '@/views/dashboard/Events.vue'),
      //       meta: {
      //         event_type: 'function',
      //         crumb: 'events',
      //       },
      //     },
      //     {
      //       path: ':eventId',
      //       name: 'DashboardEventDetail',
      // eslint-disable-next-line max-len
      //       component: () => import(/* webpackChunkName "dashboard-events" */ '@/views/dashboard/EventDetail.vue'),
      //       meta: {
      //         event_type: 'function',
      //         crumb: 'event details',
      //       },
      //     },
      //   ],
      //   meta: {
      //     event_type: 'function',
      //     crumb: 'events',
      //   },
      // },
      {
        path: 'experiences',
        component: {
          render(c) {
            return c('router-view');
          },
        },
        children: [
          {
            path: '',
            name: 'DashboardExperiences',
            component: () => import(/* webpackChunkName "dashboard-events" */ '@/views/dashboard/Events.vue'),
            meta: {
              event_type: 'experience',
              crumb: 'experiences',
            },
          },
          {
            path: ':eventId',
            name: 'DashboardExperienceDetail',
            component: () => import(/* webpackChunkName "dashboard-events" */ '@/views/dashboard/EventDetail.vue'),
            meta: {
              event_type: 'experience',
              crumb: 'experience detail',
            },
          },
        ],
        meta: {
          event_type: 'experience',
          crumb: 'experiences',
        },
      },
      {
        path: 'orders',
        component: {
          render(c) {
            return c('router-view');
          },
        },
        children: [
          {
            path: '',
            name: 'DashboardOrders',
            component: () => import(/* webpackChunkName "dashboard-orders" */ '@/views/dashboard/Orders.vue'),
            meta: {
              crumb: 'orders',
            },
          },
        ],
      },
      {
        path: 'payments',
        component: {
          render(c) {
            return c('router-view');
          },
        },
        children: [
          {
            path: '',
            name: 'StripePayments',
            component: () => import(/* webpackChunkName "payments" */ '@/views/dashboard/StripePayments.vue'),
            meta: {
              crumb: 'payments',
            },
          },
        ],
      },
      {
        path: 'settings',
        name: 'DashboardSettings',
        component: () => import(/* webpackChunkName "dashboard-orders" */ '@/views/dashboard/Settings.vue'),
        meta: {
          crumb: 'settings',
        },
      },
      {
        path: 'access-requests',
        name: 'DashboardAccessRequests',
        component: () => import(/* webpackChunkName "dashboard-orders" */ '@/views/dashboard/AccessRequests.vue'),
        meta: {
          crumb: 'access requests',
          staffOnly: true,
        },
      },
    ],
  },
];

export default routes.map((route) => {
  const meta = {
    public: false,
  };

  return { ...route, meta };
});
