import AuthService from './AuthService';
import EventsService from './EventsService';
import EventDateService from './EventDateService';
import TagService from './TagService';
import VenueService from './VenueService';
import ImageService from './ImageService';
import RootService from './RootService';
import TicketService from './TicketService';
import ProfileService from './ProfileService';
import DashboardService from './DashboardService';
import OrderService from './OrderService';
import AccessRequestService from './AccessRequestService';
import PaymentsService from './PaymentsService';

const services = {
  auth: AuthService,
  events: EventsService,
  eventDates: EventDateService,
  tags: TagService,
  venues: VenueService,
  images: ImageService,
  root: RootService,
  tickets: TicketService,
  profile: ProfileService,
  dashboard: DashboardService,
  orders: OrderService,
  accessRequests: AccessRequestService,
  payments: PaymentsService,
};

const ServiceFactory = {
  get: (name) => services[name],
};

export default ServiceFactory;
