import EventsServer from './servers/events';

const RESOURCE = '/tags';

function get(page, pageSize) {
  return EventsServer.get(
    `${RESOURCE}/`,
    {
      params: {
        page,
        page_size: pageSize,
      },
    },
  );
}

function getTag(tagId) {
  return EventsServer.get(
    `${RESOURCE}/${tagId}/`,
  );
}

export default {
  get,
  getTag,
};
