import ServiceFactory from '@/api';

const OrderService = ServiceFactory.get('orders');

function getOrders({ commit }, payload) {
  commit('toggleLoadingStatus');

  let data = payload;
  if (!data) {
    data = {};
  }
  let { page, pageSize } = data;
  const { event } = data;
  if (!page) {
    page = 1;
  }

  if (!pageSize) {
    pageSize = 10;
  }

  if (page === 1) {
    commit('clearItems');
  }
  return OrderService.get({ page, pageSize, event })
    .then((response) => {
      if (response.success) {
        if (response.data && response.data.objects) {
          if (page === 1) {
            commit('setItems', response.data.objects);
          } else {
            commit('addItems', response.data.objects);
          }
          commit('setHasMoreContent', !!response.data.next);
        }
      }

      return response;
    })
    .finally(() => {
      commit('toggleLoadingStatus');
    });
}

function getOrder({ commit }, orderId) {
  commit('toggleLoadingStatus');

  return OrderService.getOrder(orderId)
    .finally(() => {
      commit('toggleLoadingStatus');
    });
}

export default {
  getOrders,
  getOrder,
};
