import EventsServer from './servers/events';

const RESOURCE = '/tickets';

function get({ page, pageSize, eventId }) {
  const params = {
    page,
    page_size: pageSize,
    all: 1,
  };
  if (eventId) {
    params.event = eventId;
  }
  return EventsServer.get(
    `${RESOURCE}/`,
    {
      params,
    },
  );
}

function getTicket(ticketId) {
  return EventsServer.get(
    `${RESOURCE}/${ticketId}/`,
  );
}

function createTicket(data) {
  return EventsServer.post(
    `${RESOURCE}/`,
    data,
  );
}

function updateTicket(ticketId, data) {
  return EventsServer.patch(
    `${RESOURCE}/${ticketId}/`,
    data,
  );
}

function deleteTicket(ticketId) {
  return EventsServer.delete(
    `${RESOURCE}/${ticketId}/`,
  );
}

export default {
  get,
  getTicket,
  createTicket,
  updateTicket,
  deleteTicket,
};
