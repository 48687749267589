export default {
  setItems(state, items) {
    state.items = items;
  },

  clearItems(state) {
    state.items = [];
  },

  addItems(state, items) {
    state.items = [...state.items, ...items];
  },

  toggleLoadingStatus(state, field) {
    let key = field;
    if (!key) {
      key = 'items';
    }
    const d = {};
    d[key] = !state.isLoading[key];
    state.isLoading = { ...state.isLoading, ...d };
  },
};
