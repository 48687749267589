import EventsServer from './servers/events';

const RESOURCE = '/images';

function uploadImage(data) {
  const formData = new FormData();
  formData.append('image', data.file);

  Object.keys(data)
    .filter((k) => k !== 'file')
    .forEach((k) => {
      formData.append(k, data[k]);
    });
  return EventsServer.post(
    `${RESOURCE}/`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      timeout: 60000,
    },
  );
}

function get() {
  return EventsServer.get(
    `${RESOURCE}/`,
  );
}

function getImage(imageId) {
  return EventsServer.get(
    `${RESOURCE}/${imageId}/`,
  );
}

export default {
  get,
  getImage,
  uploadImage,
};
