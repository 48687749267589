const config = {
  timeout: 60000,
  headers: { 'Content-Type': 'application/json' },
  validateStatus(status) {
    return status >= 200 && status < 500;
  },
};

export {
  // eslint-disable-next-line import/prefer-default-export
  config,
};
