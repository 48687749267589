import notify from '@/notifications';
import TokenStorage from '@/TokenStorage';

// eslint-disable-next-line no-unused-vars
function getStore() {
  // eslint-disable-next-line global-require
  const Store = require('@/store');
  return Store.default;
}

function responseParser(response) {
  let result = {
    success: false,
    logout: false,
    errors: {},
    data: {},
  };

  if (response) {
    const { status } = response;
    if (status >= 200 && status < 300) {
      // success
      if (!response.data) { // an empty body was returned
        result.success = true;
      } else {
        result = response.data;
      }
    } else {
      switch (status) {
        case 401:
          result.logout = true;
          notify({
            type: 'error',
            message: 'You have been logged out',
          });
          TokenStorage.clearTokens();
          setTimeout(() => {
            window.location.reload();
          }, 3000);
          break;

        case 403:
          if (response.data) {
            const body = response.data;
            if (body.message) {
              notify({
                type: 'error',
                message: body.message,
              });
            }
            if (body.errors) {
              result.errors = body.errors;
              // result
            } else {
              // errors
              notify({
                type: 'error',
                message: 'An unknown error occurred',
              });
            }
          }
          break;

        case 400:
          if (response.data) {
            const body = response.data;
            if (body.errors) {
              notify({
                type: 'error',
                message: 'An error occurred in your request',
              });
              result.errors = body.errors;
              // result
            } else {
              // errors
              notify({
                type: 'error',
                message: 'unknown api error',
              });
            }
          }
          break;

        case 404:
          notify({
            type: 'error',
            message: 'Content not available',
          });
          break;

        case 429:
          notify({
            type: 'error',
            message: 'Too many requests',
          });
          break;

        default:
          notify({
            type: 'error',
            message: 'Unknown error',
          });
          break;
      }
    }
  }

  return result;
}

function request(config) {
  if (!config.headers.Authorization) {
    const token = TokenStorage.getToken();
    console.debug(`bearer token ${token}`);

    if (token) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${token}`;
    }
  }

  return config;
}

export default {
  responseParser,
  request,
};
