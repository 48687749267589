import ServiceFactory from '@/api';

const PaymentsService = ServiceFactory.get('payments');

function startOnboarding({ commit }) {
  commit('toggleLoadingStatus');

  return PaymentsService.startOnboard()
    .then((response) => {
      if (response.success && response.data) {
        window.location = response.data.url;
      }
      return response;
    })
    .finally(() => {
      commit('toggleLoadingStatus');
    });
}

function getOnboardingStatus({ commit }) {
  commit('toggleLoadingStatus');

  return PaymentsService.getStatus()
    .then((response) => {
      if (response.success && response.data) {
        commit('setStatus', response.data.charges_enabled);
      }
      return response.data.charges_enabled;
    })
    .finally(() => {
      commit('toggleLoadingStatus');
    });
}

export default {
  startOnboarding,
  getOnboardingStatus,
};
