import EventsServer from './servers/events';

const RESOURCE = '/access-requests';

function get({
  page, pageSize, requestId, state,
}) {
  const params = {
    page,
    page_size: pageSize,
    all: 1,
  };
  if (requestId) {
    params.request = requestId;
  }
  if (state != null) {
    params.state = state;
  }
  return EventsServer.get(
    `${RESOURCE}/`,
    {
      params,
    },
  );
}

function getAccessRequest(requestId) {
  return EventsServer.get(
    `${RESOURCE}/${requestId}/`,
  );
}

function createAccessRequest(data) {
  return EventsServer.post(
    `${RESOURCE}/`,
    data,
  );
}

function approveAccessRequest(requestId, isApproved) {
  const data = {
    approve: isApproved,
  };
  return EventsServer.post(
    `${RESOURCE}/${requestId}/approve/`,
    data,
  );
}

export default {
  get,
  getAccessRequest,
  createAccessRequest,
  approveAccessRequest,
};
