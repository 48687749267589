export default {
  toggleLoadingStatus(state, field) {
    let key = field;
    if (!key) {
      key = 'main';
    }
    const d = {};
    d[key] = !state.isLoading[key];
    state.isLoading = { ...state.isLoading, ...d };
  },

  setEventData(state, value) {
    state.eventData = value;
  },

  setTicketData(state, value) {
    state.ticketData = value;
  },
};
