<template>
  <div id="app" class="container-fluid">
    <router-view/>
  </div>
</template>

<script>
export default {
  created() {
    if (!this.$store.state['auth/accessToken']) {
      this.$store.dispatch('auth/loadTokens');
    }
  },
};
</script>

<style lang="scss">
#app {
  font-family: $font-poppins;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // padding-right: 0;
  // padding-left: 0;
}

html, body {
  height: 100%;
  overflow: auto;
}

body {
  min-height: 100%;
}

.wham-primary-color {
  background-color: $wham-primary-color;
}

.no-text-decoration {
  text-decoration: none !important;
}

.pointer-on-hover {
  cursor: pointer;
}

.underline-on-hover:hover {
  text-decoration: underline;
}

.text-primary {
  color: $wham-primary-color !important;
}

.text-primary-on-hover:hover {
  color: $wham-primary-color !important;
}

.font-14 { font-size: 14px; }

.font-12 { font-size: 12px; }

.font-8 { font-size: 8px; }

.font-16 { font-size: 16px; }

.font-24 { font-size: 24px; }

.custom-invalid-feedback {
  font-size: 80%;
  margin-top: .25rem;
}
</style>
