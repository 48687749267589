import ServiceFactory from '@/api';

const VenueService = ServiceFactory.get('venues');

function getVenues({ commit }, payload) {
  commit('toggleLoadingStatus');

  let data = payload;
  if (!data) {
    data = {};
  }
  let { page, pageSize } = data;
  if (!page) {
    page = 1;
  }

  if (!pageSize) {
    pageSize = 10;
  }
  return VenueService.get(page, pageSize)
    .then((response) => {
      if (response.success) {
        if (response.data && response.data.objects) {
          commit('setItems', response.data.objects);
        }
      }

      return response;
    })
    .finally(() => {
      commit('toggleLoadingStatus');
    });
}

function getVenue({ commit }, venueId) {
  commit('toggleLoadingStatus');

  return VenueService.getVenue(venueId)
    .finally(() => {
      commit('toggleLoadingStatus');
    });
}

export default {
  getVenues,
  getVenue,
};
