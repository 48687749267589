export default {
  setEvents(state, events) {
    state.events = events;
  },

  clearEvents(state) {
    state.events = [];
  },

  addEvents(state, events) {
    state.events = [...state.events, ...events];
  },

  clearExperiences(state) {
    state.events = [];
  },

  clearPages(state) {
    state.pages = [];
  },

  toggleLoadingStatus(state) {
    state.isLoading = !state.isLoading;
  },

  setHasMoreContent(state, value) {
    state.hasMoreContent = value;
  },

  updateSearchResults(state, value) {
    state.searchResults = value;
  },

  clearSearchResults(state) {
    state.searchResults = [];
  },

  toggleSearchLoadingStatus(state) {
    state.isSearchLoading = !state.isSearchLoading;
  },
};
