const state = {
  isLoading: {
    items: false,
    approval: false,
    create: false,
  },
  items: [],
};

export default state;
