import EventsServer from './servers/events';

const RESOURCE = '/orders';

function get({ page, pageSize, event }) {
  const params = {
    page,
    page_size: pageSize,
  };
  if (event) {
    params.event = event;
  }
  return EventsServer.get(
    `${RESOURCE}/`,
    {
      params,
    },
  );
}

function getOrder(orderId) {
  return EventsServer.get(
    `${RESOURCE}/${orderId}/`,
  );
}

export default {
  get,
  getOrder,
};
