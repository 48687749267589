import axios from 'axios';

import Interceptors from './interceptors';
import { config } from './common';

const AUTH_SERVER = process.env.VUE_APP_AUTH_SERVER;
const AUTH_API_ENDPOINT = process.env.VUE_APP_AUTH_API_ENDPOINT;

const authInstance = axios.create({
  baseURL: `${AUTH_SERVER}${AUTH_API_ENDPOINT}`,
  ...config,
});

const tokenInstance = axios.create({
  baseURL: AUTH_SERVER,
  ...config,
});

authInstance.interceptors.response.use(
  (response) => Interceptors.responseParser(response),
);

tokenInstance.interceptors.response.use(
  (response) => Interceptors.responseParser(response),
);

export default {
  main: authInstance,
  token: tokenInstance,
};
