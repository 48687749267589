import TokenStorage from '@/TokenStorage';

export default {
  isLoggedIn: (state) => {
    let { accessToken } = state;
    if (!accessToken) {
      accessToken = TokenStorage.getToken();
    }
    return !!accessToken;
  },

  isLoading: (state) => (field) => {
    let key = field;
    if (!key) {
      key = 'main';
    }
    return state.isLoading[key];
  },
};
