const state = {
  isLoading: false,
  firstName: null,
  lastName: null,
  email: null,
  lastUpdated: null,
  timezone: null,
  avatar: '',
  settings: '',
  isStaff: false,
};

export default state;
