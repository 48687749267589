const state = {
  isLoading: {
    main: false,
    countInfo: false,
  },
  eventData: {},
  ticketData: {},
};

export default state;
