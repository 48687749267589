export default {
  toggleLoadingStatus(state) {
    state.isLoading = !state.isLoading;
  },

  setFirstName(state, value) {
    state.firstName = value || '';
  },

  setLastName(state, value) {
    state.lastName = value || '';
  },

  setEmail(state, value) {
    state.email = value || '';
  },

  updateLastUpdate(state, value) {
    // assuming value is an iso string
    state.lastUpdated = new Date(value);
  },

  setTimezone(state, value) {
    state.timezone = value;
  },

  setAvatar(state, value) {
    state.avatar = value;
  },

  updateSettings(state, value) {
    state.settings = value || {};
  },

  setIsStaff(state, value) {
    state.isStaff = value;
  },
};
