import Cookies from 'js-cookie';

function storeTokens(payload) {
  Cookies.set('accessToken', payload.accessToken);
  Cookies.set('refreshToken', payload.refreshToken);
}

function clearTokens() {
  Cookies.remove('accessToken');
  Cookies.remove('refreshToken');
}

function getToken(type) {
  let token = null;
  if (type === 'refresh') {
    token = 'refreshToken';
  } else {
    token = 'accessToken';
  }
  return Cookies.get(token);
}

function getTokens() {
  const accessToken = Cookies.get('accessToken');
  const refreshToken = Cookies.get('refreshToken');
  return { accessToken, refreshToken };
}

export default {
  storeTokens,
  clearTokens,
  getToken,
  getTokens,
};
