import ServiceFactory from '@/api';

const DashboardService = ServiceFactory.get('dashboard');

function getDashboardData({ commit }) {
  commit('toggleLoadingStatus', 'countInfo');

  return DashboardService.get()
    .then((response) => {
      const { success, data } = response;
      if (success) {
        commit('setEventData', data.event);
        commit('setTicketData', data.ticket);
      }

      return response;
    })
    .finally(() => {
      commit('toggleLoadingStatus', 'countInfo');
    });
}

export default {
  getDashboardData,
};
