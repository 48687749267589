import ServiceFactory from '@/api';

const RequestService = ServiceFactory.get('accessRequests');

function getAccessRequest({ commit }, requestId) {
  commit('toggleLoadingStatus');

  return RequestService.getAccessRequest(requestId)
    .finally(() => {
      commit('toggleLoadingStatus');
    });
}

function getAccessRequests({ commit }, payload) {
  commit('toggleLoadingStatus');

  let data = payload;
  if (!data) {
    data = {};
  }
  let { page, pageSize } = data;
  const { eventId, state } = data;
  if (!page) {
    page = 1;
  }

  if (!pageSize) {
    pageSize = 10;
  }
  console.debug(`state is ${state}`);
  return RequestService.get({
    page, pageSize, eventId, state,
  })
    .then((response) => {
      if (response.success) {
        if (response.data && response.data.objects) {
          const { objects } = response.data;
          if (page === 1) {
            commit('setItems', objects);
          } else {
            commit('addItems', objects);
          }
        }
      }

      return response;
    })
    .finally(() => {
      commit('toggleLoadingStatus');
    });
}

function createAccessRequest({ commit }, data) {
  const loadingKey = 'create';
  commit('toggleLoadingStatus', loadingKey);

  return RequestService.createAccessRequest(data)
    .finally(() => {
      commit('toggleLoadingStatus', loadingKey);
    });
}

function approveAccessRequest({ commit }, data) {
  const loadingKey = 'approval';
  const { requestId, isApproved } = data;
  commit('toggleLoadingStatus', loadingKey);
  return RequestService.approveAccessRequest(requestId, isApproved)
    .finally(() => {
      commit('toggleLoadingStatus', loadingKey);
    });
}

export default {
  getAccessRequest,
  getAccessRequests,
  createAccessRequest,
  approveAccessRequest,
};
