import EventsServer from './servers/events';

const RESOURCE = '/dashboard';

function get() {
  return EventsServer.get(
    `${RESOURCE}/`,
  );
}

export default {
  get,
};
