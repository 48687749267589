import ServiceFactory from '@/api';

const ProfileService = ServiceFactory.get('profile');

function commitProfileChanges(commit, data) {
  const { user } = data;
  commit('updateLastUpdate', data.last_updated);
  commit('setTimezone', data.timezone);
  commit('updateSettings', JSON.parse(data.settings));
  commit('setFirstName', user.first_name);
  commit('setLastName', user.last_name);
  commit('setEmail', user.email);
  commit('setAvatar', data.avatar);
  commit('setIsStaff', user.is_staff);
}

function getProfile({ commit }) {
  commit('toggleLoadingStatus');

  return ProfileService.get()
    .then((response) => {
      const { success, data } = response;
      if (success) {
        commitProfileChanges(commit, data);
      }

      return response;
    })
    .finally(() => {
      commit('toggleLoadingStatus');
    });
}

function updateProfile({ commit }, payload) {
  commit('toggleLoadingStatus');

  return ProfileService.update(payload)
    .then((response) => {
      const { success, data } = response;
      if (success) {
        commitProfileChanges(commit, data);
      }

      return response;
    })
    .finally(() => {
      commit('toggleLoadingStatus');
    });
}

export default {
  getProfile,
  updateProfile,
};
