import EventsServer from './servers/events';

const START_ONBOARD = '/payments/onboard';
const ONBOARD_STATUS = '/payments/return';

function startOnboard() {
  return EventsServer.post(`${START_ONBOARD}/`);
}

function getStatus() {
  return EventsServer.get(`${ONBOARD_STATUS}/`);
}

export default {
  startOnboard,
  getStatus,
};
