import ServiceFactory from '@/api';

const ImageService = ServiceFactory.get('images');

function getImages({ commit }, payload) {
  commit('toggleLoadingStatus');

  let data = payload;
  if (!data) {
    data = {};
  }
  let { page, pageSize } = data;
  if (!page) {
    page = 1;
  }

  if (!pageSize) {
    pageSize = 10;
  }
  return ImageService.get(page, pageSize)
    .then((response) => {
      if (response.success) {
        if (response.data && response.data.objects) {
          commit('setItems', response.data.objects);
        }
      }

      return response;
    })
    .finally(() => {
      commit('toggleLoadingStatus');
    });
}

function getImage({ commit }, imageId) {
  commit('toggleLoadingStatus');

  return ImageService.getImage(imageId)
    .finally(() => {
      commit('toggleLoadingStatus');
    });
}

function uploadImage({ commit }, payload) {
  const { loading, ...data } = payload;
  commit('toggleLoadingStatus', loading);

  return ImageService.uploadImage(data)
    .finally(() => {
      commit('toggleLoadingStatus', loading);
    });
}

export default {
  getImages,
  getImage,
  uploadImage,
};
