import ServiceFactory from '@/api';

const EventDateService = ServiceFactory.get('eventDates');

function updateEventDate({ commit }, payload) {
  commit('toggleLoadingStatus');

  const { eventDateId, data } = payload;
  return EventDateService.updateEventDate(eventDateId, data)
    .then((response) => {
      if (response.success) {
        if (response.data) {
          commit('setItems', response.data);
        }
      }

      return response;
    })
    .finally(() => {
      commit('toggleLoadingStatus');
    });
}

function deleteEventDate({ commit }, eventDateId) {
  commit('toggleLoadingStatus');

  return EventDateService.deleteEventDate(eventDateId)
    .finally(() => {
      commit('toggleLoadingStatus');
    });
}

export default {
  updateEventDate,
  deleteEventDate,
};
