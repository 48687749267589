import ServiceFactory from '@/api';
import _ from 'lodash';

const EventsService = ServiceFactory.get('events');

function getEvents({ commit }, payload) {
  commit('toggleLoadingStatus');

  let data = payload;
  if (!data) {
    data = {};
  }
  let { page, pageSize } = data;
  if (!page) {
    page = 1;
  }

  if (!pageSize) {
    pageSize = 10;
  }
  if (page === 1) {
    commit('clearEvents');
  }
  return EventsService.get(page, pageSize)
    .then((response) => {
      if (response.success) {
        if (response.data && response.data.objects) {
          if (page === 1) {
            commit('setEvents', response.data.objects);
          } else {
            commit('addEvents', response.data.objects);
          }
          commit('setHasMoreContent', !!response.data.next);
        }
      }

      return response;
    })
    .finally(() => {
      commit('toggleLoadingStatus');
    });
}

function getEvent({ commit }, eventId) {
  commit('toggleLoadingStatus');

  return EventsService.getEvent(eventId)
    .finally(() => {
      commit('toggleLoadingStatus');
    });
}

function getExperiences({ commit }, payload) {
  commit('toggleLoadingStatus');

  let data = payload;
  if (!data) {
    data = {};
  }
  let { page, pageSize } = data;
  if (!page) {
    page = 1;
  }

  if (!pageSize) {
    pageSize = 10;
  }
  if (page === 1) {
    commit('clearEvents');
  }
  return EventsService.getExperiences(page, pageSize)
    .then((response) => {
      if (response.success) {
        if (response.data && response.data.objects) {
          if (page === 1) {
            commit('setEvents', response.data.objects);
          } else {
            commit('addEvents', response.data.objects);
          }
          commit('setHasMoreContent', !!response.data.next);
        }
      }

      return response;
    })
    .finally(() => {
      commit('toggleLoadingStatus');
    });
}

function getExperience({ commit }, experienceId) {
  commit('toggleLoadingStatus');

  return EventsService.getExperience(experienceId)
    .finally(() => {
      commit('toggleLoadingStatus');
    });
}

function createEvent({ commit }, data) {
  commit('toggleLoadingStatus');

  return EventsService.createEvent(data)
    .finally(() => {
      commit('toggleLoadingStatus');
    });
}

function updateEvent({ commit }, payload) {
  commit('toggleLoadingStatus');

  const { eventId, data } = payload;
  return EventsService.updateEvent(eventId, data)
    .finally(() => {
      commit('toggleLoadingStatus');
    });
}

function deleteEvent({ commit }, eventId) {
  commit('toggleLoadingStatus');

  return EventsService.deleteEvent(eventId)
    .finally(() => {
      commit('toggleLoadingStatus');
    });
}

function getEventNames({ commit }, payload) {
  commit('toggleLoadingStatus');

  let data = payload;
  if (_.isEmpty(data)) {
    data = {};
  }
  let { page, pageSize } = data;
  const { searchQuery, hasOrders } = data;
  if (!page) {
    page = 1;
  }

  if (!pageSize) {
    pageSize = 20;
  }

  if (page === 1) {
    commit('clearSearchResults');
  }
  console.debug(`search query: ${searchQuery}`);
  return EventsService.getEventNames({
    page, pageSize, searchQuery, hasOrders,
  })
    .then((response) => {
      if (response.success) {
        if (response.data && response.data.objects) {
          if (page === 1) {
            commit('setEvents', response.data.objects);
          } else {
            commit('addEvents', response.data.objects);
          }
          commit('setHasMoreContent', !!response.data.next);
        }
      }

      return response;
    })
    .finally(() => {
      commit('toggleLoadingStatus');
    });
}

function searchEvents({ commit }, payload) {
  commit('toggleSearchLoadingStatus');

  let data = payload;
  if (_.isEmpty(data)) {
    data = {};
  }
  let { page, pageSize } = data;
  const { searchQuery, hasOrders } = data;
  if (!page) {
    page = 1;
  }

  if (!pageSize) {
    pageSize = 20;
  }

  if (page === 1) {
    commit('clearSearchResults');
  }
  console.debug(`search query: ${searchQuery}`);
  return EventsService.getEventNames({
    page, pageSize, searchQuery, hasOrders,
  })
    .then((response) => {
      if (response.success) {
        if (response.data && response.data.objects) {
          commit('updateSearchResults', response.data.objects);
        }
      }

      return response;
    })
    .finally(() => {
      commit('toggleSearchLoadingStatus');
    });
}

export default {
  getEvents,
  getEvent,
  getExperiences,
  getExperience,
  createEvent,
  updateEvent,
  deleteEvent,
  getEventNames,
  searchEvents,
};
