import EventsServer from './servers/events';

const RESOURCE = '/profile';

function get() {
  return EventsServer.get(
    `${RESOURCE}/`,
  );
}

function update(data) {
  return EventsServer.patch(
    `${RESOURCE}/`,
    data,
  );
}

export default {
  get,
  update,
};
