import Vue from 'vue';

const options = {
  position: 'bottom-right',
  duration: 3000,
  keepOnHover: true,
  iconPack: 'material',
  theme: 'outline',
};

function notify(payload) {
  const { type, message } = payload;
  Vue.toasted.show(message, { type, ...options });
}

export default notify;
