import TokenStorage from '@/TokenStorage';

export default {
  setOtpSessionKey(state, key) {
    state.otpSessionKey = key;
  },

  resetOtpSessionKey(state) {
    state.otpSessionKey = '';
  },

  setTokenValues(state, payload) {
    state.accessToken = payload.accessToken;
    state.refreshToken = payload.refreshToken;
    // need to conver to time
    // state.expiresIn = payload.expiresIn;
    TokenStorage.storeTokens(payload);
  },

  clearTokenValues(state) {
    state.accessToken = '';
    state.refreshToken = '';
    TokenStorage.clearTokens();
  },

  toggleLoadingStatus(state, field) {
    let key = field;
    if (!key) {
      key = 'main';
    }
    const d = {};
    d[key] = !state.isLoading[key];
    state.isLoading = { ...state.isLoading, ...d };
  },
};
